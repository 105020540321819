body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: Verdana, Ariel, sans;
  font-size: 10pt;
  min-height: 100%; }

#main {
  padding: 1em;
  position: relative; }

a, a:visited, a:active {
  color: #900 !important;
  text-decoration: none; }

a:hover {
  text-decoration: underline;
  color: #900 !important; }

h2 {
  margin-top: 0 !important; }

#app-container {
  padding: 0;
  background: #CCC;
  color: black; }
  #app-container .app-header {
    position: relative;
    overflow: auto;
    padding: 1em;
    padding-bottom: 0; }
    #app-container .app-header .img-container {
      height: 115px;
      width: 100%;
      position: relative;
      background: #444;
      border-radius: 15px;
      padding: 10px;
      display: inline-flex;
      justify-content: space-between; }
    #app-container .app-header .page-title {
      font: bold 22pt monospace;
      padding: 5px;
      position: absolute;
      left: 1em;
      bottom: 0em;
      color: #FFF;
      text-shadow: 3px 3px 2px gray; }

.mobile-header {
  background-color: black;
  color: white; }
  .mobile-header a {
    color: white !important; }
    .mobile-header a:visited {
      color: white !important; }
    .mobile-header a:hover {
      text-decoration: none !important; }
  .mobile-header .mobile-navbar {
    display: flex;
    justify-content: space-between;
    background-color: black;
    padding: 0.5em; }
    .mobile-header .mobile-navbar .mobile-navbar-title {
      margin: 0;
      font-size: 1.5em; }
    .mobile-header .mobile-navbar .button {
      cursor: pointer;
      font-size: 1em; }
      .mobile-header .mobile-navbar .button i {
        font-size: 1.5em; }
  .mobile-header ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .mobile-header ul li {
      text-align: right;
      border-top: thin solid #CCC; }
      .mobile-header ul li a {
        display: block;
        padding: 0.5em; }
        .mobile-header ul li a:hover {
          background-color: #999; }
        .mobile-header ul li a.active {
          background-color: white;
          color: black !important; }

#nav {
  margin-top: 1em;
  padding: 0;
  width: 100%; }
  #nav ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%; }
    #nav ul li {
      flex: 1; }
      #nav ul li a {
        background-color: black;
        display: block;
        color: white !important;
        padding: 0.5em;
        text-align: center;
        text-decoration: none; }
        #nav ul li a:visited {
          color: white !important; }
        #nav ul li a:hover {
          background-color: #999;
          color: white !important; }
        #nav ul li a.active {
          background-color: white;
          color: black !important; }

.desktop-header {
  display: none; }

.covers .cover {
  margin-top: 1em;
}

.covers .cover .card-body {
  text-align: center;
}

.covers a, .covers a:hover, .covers a:visited, .covers a:active {
  text-decoration: none !important;
  color: black !important;
}

.covers-modal .modal-body {
  text-align: center;
}

.not-found p {
  padding: 2em;
  font-size: 2em;
}

/***********************
 * Footer
 **********************/
footer {
  background: #444;
  color: white;
  text-align: center;
  padding: 0.5em; }

@media (min-width: 400px) {
  .desktop-header {
    display: block; }
  #app-container {
    margin-top: 2em;
    border-radius: 15px; }
  .mobile-header {
    display: none; }
  footer {
    border-radius: 0 0 15px 15px; } }
