.clear {
  clear: both; }

#resume {
  width: 100%;
  background: white;
  padding: 2em; }

#resume * {
  font-size: 10pt;
  font-family: Verdana, Geneva, sans-serif; }

#resume .resume-header-wrapper {
  margin: 0 auto;
  padding-bottom: 1em; }

#resume .resume-header {
  text-align: center; }

#resume .resume-name {
  font-size: 20pt;
  text-align: left;
  border-bottom: black solid 1px; }

#resume .resume-info {
  text-align: center;
  font-size: 8pt;
  font-weight: bold; }

#resume .resume-info a {
  font-size: 8pt; }

#resume .left-col {
  font-weight: bold;
  padding-right: 0;
  padding-left: 0; }

#resume .right-col {
  padding-left: 0; }

#resume .title-date .title {
  padding-left: 0;
  font-style: italic; }

#resume .title-date .date {
  text-align: right; }

#resume .skillsets {
  list-style: none; }

/***********************************
 * Indenting
 **********************************/
.indent-after-first-line {
  text-indent: -1em;
  margin-left: 1em; }

.indent0 {
  padding-left: 0;
  margin-left: 0em; }

.indent1 {
  padding-left: 0;
  margin-left: 0.5em; }

.indent2 {
  padding-left: 0;
  margin-left: 1em; }

.indent3 {
  padding-left: 0;
  margin-left: 1.5em; }

/***************************
 * Link styles for when template
 * not included
 ***************************/
a, a:visited, a:active {
  color: #990000;
  text-decoration: none; }

@page {
  margin: 0; }

@media print {
  #app-header, #nav, .mobile-header, .sidebar, footer, .site-side-padding, .screen-only {
    display: none; }
  a[href]:after {
    content: none !important; }
  #page, #content-wrapper, #content, .container {
    width: 100%;
    margin: 0;
    padding: 0; }
  #resume, #main, .wrapper {
    width: 100% !important;
    margin: 0 !important;
    padding: 0.5em !important; }
    #resume .project .link, #main .project .link, .wrapper .project .link {
      text-align: right; }
  #app-container {
    width: 100% !important;
    margin: 0 !important;
    border-radius: 0; }
  /**
  deal with bootstrap grid http://stackoverflow.com/questions/20243767/printing-page-with-bootstrap-3#answer-20244712
  Note: You probably need to cover any breakpoints you use, e.g., .col-xs-*, .col-sm-*, etc.
  */
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12, .col-xs-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666666666666%; }
  .col-md-10 {
    width: 83.33333333333334%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666666666666%; }
  .col-md-7 {
    width: 58.333333333333336%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666666666667%; }
  .col-md-4 {
    width: 33.33333333333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.666666666666664%; }
  .col-md-1 {
    width: 8.333333333333332%; } }
